module.exports = {
    setAnimationsManagerOnBoltInstance: (boltInstance, animationManager, warmupAnimationsStarted, stopWarmupAnimations) => {
        stopWarmupAnimations()
        boltInstance.setAnimationManager(animationManager)
    },

    setPendingRuntimeOnBoltInstance: (boltInstance, runtime) => {
        boltInstance.setPendingRuntime(runtime)
    }
}
