const {mapLanguageCodeToName} = require('santa-multilingual/dist/languages')
const {WorkerStates, getKeyInStatesMap} = require('./platformInitConstants')
const {getContextId} = require('santa-renderer/src/utils/contextIdUtils')

const buildScriptsSources = (serviceTopology, options = {}) => {
    const sdkSource = `${serviceTopology.scriptsLocationMap['js-wixcode-sdk']}/lib/${
        options.isDebug ? 'wix.js' : 'wix.min.js'}`
    const namespacesSdkSource = `${serviceTopology.scriptsLocationMap['wixcode-namespaces']}/${
        options.isDebug ? 'wixcode-namespaces.js' : 'wixcode-namespaces.min.js'}`
    const externalComponentsSource = `${serviceTopology.scriptsLocationMap['wix-ui-santa']}/wixcode/${
        options.isDebug ? 'wixcode-components.js' : 'wixcode-components.min.js'}`
    const wixCodeNamespacesAndElementorySupportSource = `${serviceTopology.scriptsLocationMap['wix-code-platform']}/wixCodeNamespacesAndElementorySupport.min.js`

    return {sdkSource, namespacesSdkSource, externalComponentsSource, wixCodeNamespacesAndElementorySupportSource}
}

const getBootstrapMessage = payload => {
    const {
        serviceTopology,
        rendererModel,
        applications,
        wixBiSession,
        isInSSR,
        // clientAfterSuccessfulSSR,
        wixCodeBase,
        openExperiments,
        csrfToken,
        requestId,
        sdkParameters,
        isDebug,
        storage
    } = payload

    const {viewerSessionId, visitorId, initialTimestamp} = wixBiSession

    const {
        sdkSource,
        namespacesSdkSource,
        externalComponentsSource,
        wixCodeNamespacesAndElementorySupportSource
    } = buildScriptsSources(serviceTopology, {isDebug})

    const bootstrapArguments = {
        sdkParameters: {
            ...sdkParameters,
            storage: storage.serialize()
        },
        debug: false, //!!(window && window.__WIX_CODE_DEBUG__),
        santaVersion: '1.6374.4', // TODO: get the real santa version
        wixCodeBase,
        sdkSource,
        namespacesSdkSource,
        externalComponentsSource,
        applications: JSON.stringify(applications), //: '[{"type":"Application","id":"dataBinding","displayName":"Application","url":"https://static.parastorage.com/services/dbsm-viewer-app/1.336.0/app.js"}]',
        wixCodeNamespacesAndElementorySupportSource,
        viewerSessionId,
        visitorId,
        ownerId: rendererModel.userId,
        initialTimestamp,
        metaSiteId: rendererModel.metaSiteId,
        openExperiments,
        pn: 1,
        isServerSide: isInSSR,
        postSSR: isInSSR,
        requestId,
        isDebug,
        csrfToken
    }
    return bootstrapArguments
}

const getContextIdFromNavInfo = ({pageId: mainRootId, innerRoute, queryParams: {lang = ''} = {}}) =>
    getContextId({mainRootId, innerRoute, lang})

const toQueryParams = obj => {
    const keys = Object.keys(obj)
    return keys.map(key => `${key}=${obj[key]}`).join('&')
}

const script_import_message = (worker, url, script) => {
    script = script.slice(0)
    worker.postMessage({
        type: 'script_import_message',
        url,
        script
    }, [script]) // this is not a mistake
}

const sendWorkerMessage = (worker, message, hasSentThatMessage, hasSentPrevMessage, setWorkerState, shouldWaitBeforeSendingMessage) => {
    if (hasSentThatMessage || !hasSentPrevMessage || shouldWaitBeforeSendingMessage) {
        return
    }
    worker.postMessage(message)
    setWorkerState()
}

const fetchScriptAndSendPostMessage = (worker, fetch, url, sendMessageFunc) => {
    fetch(url, null, 'arrayBuffer', script => sendMessageFunc(worker, url, script))
}

const stopWorkers = (workersToStop, setWorker, setWorkerState) => {
    for (const contextId in workersToStop) {
        if (workersToStop.hasOwnProperty(contextId)) {
            //this message actually asks the worker to self terminate
            workersToStop[contextId].postMessage({
                contextId,
                type: 'stop'
            })
            //TODO: we should probably add a terminated state
            setWorker(contextId, undefined)
            setWorkerState(getKeyInStatesMap(contextId, WorkerStates.BOOTSTRAP), undefined)
            setWorkerState(getKeyInStatesMap(contextId, WorkerStates.USER_CODE), undefined)
            setWorkerState(getKeyInStatesMap(contextId, WorkerStates.LOAD), undefined)
        }
    }
}

const getDeviceType = mobileDeviceAnalyzer => {
    if (mobileDeviceAnalyzer) {
        if (mobileDeviceAnalyzer.isMobileDevice()) {
            return 'mobile'
        }
        if (mobileDeviceAnalyzer.isTabletDevice()) {
            return 'tablet'
        }
        return 'desktop'
    }
    return null
}

const getReferrer = () => typeof window === 'undefined' || !window.document ? '' : window.document.referrer

module.exports = {
    getBootstrapMessage,
    buildScriptsSources,
    toQueryParams,
    script_import_message,
    sendWorkerMessage,
    getDeviceType,
    getReferrer,
    mapLanguageCodeToName,
    fetchScriptAndSendPostMessage,
    stopWorkers,
    getContextIdFromNavInfo
}
