const loading = require('../gen/init/loading')
const {getLanguageCode} = require('./init/multilingual')
const supportedLanguages = require('../gen/supportedLanguages')
const {createFunctionLibrary} = require('./init/initFunctionLibrary')
const storageFactory = require('./init/utils/storageFactory')
// const storageFactory = require('santa-host-platform-services/dist/cjs/plarformInit/utils/storageFacade')
const {updateHistory} = require('./init/functionLibrary/navigationLib')

const noop = () => {
}

function getEmptyMaps() {
    return {
        behaviors_data: {},
        connections_data: {},
        document_data: {},
        design_data: {},
        mobile_hints: {},
        component_properties: {},
        theme_data: {}
    }
}

const getCompClasses = (compClasses, {components}, boltComponents) => {
    const compTypesToClasses = compClasses || Object.keys(components).reduce((acc, compKey) => {
        const compClass = components[compKey]
        if (!compClass.compType) {
            return acc
        }

        return {
            [compClass.compType]: compClass,
            ...acc
        }
    }, {})

    compTypesToClasses[components.ThemeRendererWithFonts.compType] = components.ThemeRendererWithFonts
    compTypesToClasses[boltComponents.BoltPageGroup.compType] = boltComponents.BoltPageGroup

    return compTypesToClasses
}

function createInitialTranslations(translations) {
    return supportedLanguages.reduce((acc, languageCode) => {
        const languageTranslation = translations[languageCode]
        const emptyMap = {data: {document_data: {}}}
        return {...acc, [languageCode]: languageTranslation || emptyMap}
    }, {})
}

function isStorageSupported() {
    try {
        window.localStorage.setItem('', '')
        window.localStorage.removeItem('')
        console.log('storage is supported')
        return true
    } catch (exception) {
        console.log('storage is NOT supported')
        return false
    }
}

/*eslint fp/no-mutation:0*/
const initFactory = (logger, ssrModel, functionLib, renderingPlugins) => {
    const {fetch, requireFn, requireSync, reportActionStart, reportActionEnd, requireTPANativeCode} = functionLib
    return ({rendererModel, publicModel, serviceTopology, requestModel, rawSeoTags, analyticsTrackers, rawUrl, wixBiSession, reportBeatEvent, isBot, isDebug, santaBase, compClasses, bootstrapPackages, renderFlags}) => {
        const {isStreaming, isInSSR, boltInstanceFunctionLibrary = {}} = ssrModel

        const currentLanguage = getLanguageCode(rawUrl, rendererModel.sitePropertiesInfo)

        const mainRModel = {
            additionalStructures: {},
            pagesToLoad: [],
            pagesJsonFileName: {},
            pageRawContent: {},
            packages: bootstrapPackages || {},
            loadedComponents: {},
            sitemapQueries: {},
            boltInstance: null,
            rendererModel,
            publicModel,
            serviceTopology,
            requestModel,
            navigationModel: {
                rawUrl,
                prevParsedUrl: null,
                navigationInfos: {},
                boltInstanceNavigateCallback: null
            },
            pagesLoadingModel: {
                additionalPagesToLoad: {}
            },
            ssrModel: {
                warmupPackages: {},
                isStreaming,
                ssrSucceeded: false,
                doneWarmup: false,
                serverMarkup: '',
                isInSSR,
                warmupData: null
            },
            platformModel: {},
            workerBuffers: {},
            animationManager: null,
            warmupAnimationsStarted: false,
            isBot,
            isDebug,
            santaBase,
            workers: {},
            workersState: {},
            storage: storageFactory.get(isStorageSupported()),
            wixBiSession
        }

        return new Promise(resolve => {
            const createHostInstanceReportId = reportActionStart('createHostInstance')

            const stageResolvers = {}
            // eslint-disable-next-line promise/param-names
            const doneStagePromise = new Promise((doneStageResolved, doneStageRejected) => {
                stageResolvers.doneStageResolved = doneStageResolved
                stageResolvers.doneStageRejected = doneStageRejected
            })

            const hostInstance = loading(
                mainRModel,
                {
                    createBoltInstance: payload => {
                        const {
                            boltMain,
                            santaComponents,
                            boltComponents,
                            initialPage: {structure, data, translations = {}},
                            isMobileView,
                            mobileDeviceAnalyzer,
                            hostApi,
                            navigationInfos,
                            currentUrl,
                            platformModel,
                            storage,
                            callback
                        } = payload

                        const initialTranslations = createInitialTranslations(translations)

                        const boltInitialModel = {
                            storage,
                            currentLanguage,
                            navigationInfos,
                            currentUrl,
                            structure,
                            data,
                            loadedFonts: {},
                            translations: initialTranslations,
                            serviceTopology,
                            rendererModel,
                            publicModel,
                            rawSeoTags,
                            analyticsTrackers,
                            compClasses: getCompClasses(compClasses, santaComponents, boltComponents),
                            activeModes: {},
                            runtime: {data: getEmptyMaps(), behaviors: [], state: {}}, //TODO use runtimeUtils
                            isMobileView,
                            mobileDeviceAnalyzer,
                            wixBiSession,
                            reportBeatEvent,
                            renderFlags: renderFlags || {},
                            ssrModel: {
                                isInSSR,
                                isClientAfterSSR: isStreaming,
                                isFirstRenderAfterSSR: false
                            },
                            rootCompIds: ['BOLT_SITE'],
                            getWindowObject: isInSSR ? noop : () => window,
                            requestModel,
                            santaBase,
                            platformModel
                        }

                        const createBoltInstanceReportId = reportActionStart('createBoltInstance')
                        const boltInstance = boltMain.default.createBoltInstance(boltInitialModel, renderingPlugins, hostApi, logger, {
                            fetch,
                            requireFn,
                            requireSync,
                            reportActionStart,
                            reportActionEnd,
                            captureError: logger.captureError,
                            requireTPANativeCode,
                            ...boltInstanceFunctionLibrary
                        })
                        reportActionEnd(createBoltInstanceReportId)

                        if (!isInSSR) {
                            window.boltInstance = boltInstance
                            if (navigationInfos.primaryPage.replaceHistory) {
                                updateHistory(navigationInfos, currentUrl.full, currentUrl.protocol)
                            }
                        }

                        callback(boltInstance)
                        resolve({boltInstance, boltMain: boltMain.default, doneStagePromise})
                    },
                    done: (boltMain, boltInstance, ssrSucceeded, serverMarkup, warmupUtils, workers) => {
                        if (isInSSR) {
                            Object.values(workers).forEach(worker => worker.terminate())
                        }

                        stageResolvers.doneStageResolved({
                            hostInstance,
                            boltInstance,
                            boltMain: boltMain.default,
                            serverMarkup,
                            hydrate: ssrSucceeded,
                            indicator: warmupUtils.indicator
                        })
                    },
                    onSsrRouteRedirect: redirectPayload => resolve({redirectPayload}),
                    reportBeatEvent,
                    ...functionLib
                },
                () => {
                    setImmediate(() => {
                        hostInstance.$endBatch()
                    })
                }
            )
            reportActionEnd(createHostInstanceReportId)

            if (!isInSSR) {
                window.hostInstance = hostInstance
            }

            if (isStreaming) {
                functionLib.requireFn('zepto', $ => {
                    $(() => {
                        const {warmupData} = window
                        if (warmupData) {
                            const {
                                // userWarmup,
                                currentUrl,
                                rootNavigationInfo
                            } = window.warmupData
                            hostInstance.setParsedUrl(currentUrl)
                            if (rootNavigationInfo) {
                                hostInstance.setNavigationInfos({primaryPage: rootNavigationInfo})
                            }
                            // hostInstance.setUserWarmup(userWarmup)
                            hostInstance.setWarmupData(warmupData)
                        }
                    })
                })
            }
        })
    }
}

module.exports = {
    initFactory,
    createFunctionLibrary
}
