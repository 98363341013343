const WorkerStates = {
    BOOTSTRAP: 'BOOTSTRAP',
    LOAD: 'LOAD',
    USER_CODE: 'USER_CODE'
}

const getKeyInStatesMapCarmi = (contextId, state) => contextId.plus('-').plus(state)

const getKeyInStatesMap = (contextId, state) => `${contextId}-${state}`

module.exports = {
    WorkerStates,
    getKeyInStatesMapCarmi,
    getKeyInStatesMap
}
