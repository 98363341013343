function getLangFromUrl(url) {
    const match = url.match(/lang=(\w\w)/)
    if (!match) {
        return ''
    }
    const [, lang] = match
    return lang
}


function resolveCurrentLanguage(rawUrl, multilingualInfo) {
    if (!multilingualInfo) {
        return ''
    }
    const langFromUrl = getLangFromUrl(rawUrl)
    const doesExistInTranslationLangs = multilingualInfo.translationLanguages
        .map(x => x.languageCode)
        .filter(x => x === langFromUrl)
        .length

    if (langFromUrl && doesExistInTranslationLangs) {
        return langFromUrl
    }
    return multilingualInfo.originalLanguage.languageCode || ''
}

function getLanguageCode(rawUrl, sitePropertiesInfo) {
    const multilingualInfo = sitePropertiesInfo && sitePropertiesInfo.multilingualInfo
    return resolveCurrentLanguage(rawUrl, multilingualInfo)
}

module.exports = {
    getLanguageCode
}
