module.exports = {
    loadPage(setPageToLoad, pageId, requestInfo) {
        setPageToLoad(pageId, requestInfo)
    },
    pageRequestSuccess(setPageData, successCallback, data) {
        setPageData(data)
        if (successCallback) {
            successCallback(data)
        }
    }
}
